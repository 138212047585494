export const setRwgLocalstorage = (store_name, rwg_rending_type) => {
  // 예약 정보 삭제
  localStorage.removeItem('rwg_information')

  // 상점 이름 새로 저장
  localStorage.setItem('rwg_store_name', store_name)

  // RWG 타입 새로 저장
  localStorage.setItem('rwg_render_type', rwg_rending_type)
}

export const rwgInfoChangeTable = (data) => {
  const splitData = data.split('\n')
  const replace = splitData.map((item) => item.replace('- ', ''))

  const value = replace.map((item, index) => {
    if (index !== replace.length - 1) {
      const regex = /[:：]/ // 반각 콜론(:) 또는 전체 자 콜론(：)
      const match = item.match(regex)
      const index = match ? match.index : -1

      return [item.slice(0, index).trim(), item.slice(index + 1).trim()]
    } else {
      return item
    }
  })

  return (
    <>
      <table style={{ borderCollapse: 'collapse' }}>
        <tbody>
          {value.map(
            (item, index) =>
              index !== value.length - 1 && // 마지막 데이터는 표 밖의 메세지임
              index !== 5 && // 5 : 여권번호
              index !== 12 && // 12, 13 : SNS
              index !== 13 && (
                <tr key={index}>
                  <th
                    style={{
                      border: '1px solid black',
                      padding: '4px 8px',
                      fontWeight: 400,
                    }}
                  >
                    {item[0]}
                  </th>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '4px 8px',
                      fontWeight: 400,
                    }}
                  >
                    {item[1]}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>

      <br />
      <p>{value[value.length - 1]}</p>
    </>
  )
}
